<template>
  <div>
    <div class="font-weight-light text-subtitle-1">Gestión de usuarios</div>
    <v-form ref="formulario" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12">
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" :lg="lg(1)">
                <v-card-title class="font-weight-light text-overline">
                  Antecedentes Usuario
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" :lg="lg(2)">
                        <v-select
                          :items="tipo"
                          v-model="datosFormulario.role"
                          outlined
                          item-value="tipo"
                          label="Tipo de usuario"
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" :lg="lg(2)">
                        <v-select
                          :items="areas"
                          v-model="datosFormulario.area"
                          outlined
                          item-value="tipo"
                          label="Area de trabajo"
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" :lg="lg(2)">
                        <v-text-field
                          label="Cargo (Obligatorio)"
                          v-model="datosFormulario.cargo"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" :lg="lg(2)">
                        <v-text-field
                          label="Nombre completo (Obligatorio)"
                          v-model="datosFormulario.nombre"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" :lg="lg(2)">
                        <v-text-field
                          label="E-Mail (Obligatorio)"
                          v-model="datosFormulario.email"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_email"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-row>
                        <v-col cols="12" :lg="lg(2)">
                          <v-switch
                            v-model="datosFormulario.activo"
                            label="Estado"
                            color="primary"
                            hide-details
                            class="ml-5"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="6" v-if="usuarioDB.data.role == 'ADMIN'">
                <v-card-title class="font-weight-light text-overline">
                  Permisos
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-treeview
                          selectable
                          :items="itemsMenu"
                          selection-type="leaf"
                          return-object
                          v-model="seleccionados"
                          selected-color="primary"
                          :checkedNodes="seleccionados"
                        ></v-treeview>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-title class="font-weight-light text-overline">
                  Color de asignacion
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-radio-group v-model="datosFormulario.color" row>
                          <v-radio v-for="color in colores" :color="color" :value="color">
                            <template v-slot:label>
                              <div>
                                <strong :class="color + '--text'">Muestra</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="idDato"
                @click="editData"
                right
                >Editar</v-btn
              >
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="!idDato"
                @click="saveData"
                right
                >Grabar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
class datosReporte {
  constructor(datos = {}) {
    this.nombre = datos.nombre;
    this.email = datos.email;
    this.activo = datos.activo;
    this.role = datos.role;
    this.mods = datos.mods;
    this.cargo = datos.cargo;
    this.area = datos.area;
    this.color = datos.color;
  }
}

export default {
  data() {
    return {
      valid: true,
      basic_validation_rules_input: [
        (v) => !!v || "Campo obligatorio",
        (v) => (v && v.length <= 50) || "Campo requerido",
      ],
      basic_validation_rules_email: [
        (v) => !!v || "E-mail es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Debe ingresar un Email válido",
      ],
      basic_validation_rules_select: [(v) => !!v || "Campo obligatorio"],
      basic_validation_rules_rut: [
        (v) => !!v || "Campo obligatorio",
        (v) => this.validaRut(v) || "Rut Incorrecto",
      ],

      loading: false,
      idDato: null,
      ex11: true,

      tipo: [
        {
          tipo: "ADMIN",
          text: "Admin",
        },
        {
          tipo: "USUARIO",
          text: "Usuario",
        },
        {
          tipo: "JEFE INGENIERIA",
          text: "Jefe Ingeniería",
        },
      ],
      areas: [
        {
          tipo: "PROYECTOS",
          text: "PROYECTOS",
        },
        {
          tipo: "ITO",
          text: "ITO",
        },
      ],
      colores: [
        "red",
        "pink",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
        "grey",
        "black",
      ],

      datosFormulario: new datosReporte(new datosReporte()),

      seleccionados: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
    };
  },
  methods: {
    validate() {
      this.$refs.formulario.validate();
    },
    lg(tipo) {
      if (tipo == 1) {
        return this.usuarioDB.data.role == "ADMIN" ? 6 : 12;
      } else {
        return this.usuarioDB.data.role == "ADMIN" ? 12 : 6;
      }
    },

    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      if (this.idDato) {
        axios.get("/users/user/" + this.idDato, config).then((response) => {
          console.log(response);
          this.datosFormulario = new datosReporte(response.data);

          for (let permisos of this.datosFormulario.mods) {
            this.seleccionados.push({
              id: permisos,
              name: "",
            });
          }
        });
      } else {
      }
    },

    async editData() {
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        await axios
          .put("/users/user/" + this.idDato, this.datosFormulario, config)
          .then((response) => {
            this.$router.push({
              name: "usuarios",
            });
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },

    async saveData() {
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        if (this.datosFormulario.role == "RESIDENTES") {
          this.datosFormulario.mods = [];
          this.datosFormulario.mods.push(1);
        }

        //console.log(this.datosFormulario);
        await axios
          .post("/users/user/", this.datosFormulario, config)
          .then((response) => {
            setTimeout(() => (this.loading = false), 3000);
            this.$router.push({
              name: "usuarios",
            });
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.idDato = this.$route.params.id;
    }

    this.getData();
  },
  mounted() {},
  computed: {
    ...mapState(["token", "usuarioDB", "itemsMenu", "notificationSystem"]),
  },
  watch: {
    seleccionados: function (val) {
      this.datosFormulario.mods = [];
      for (let nodos of val) {
        this.datosFormulario.mods.push(nodos.id);
      }
    },
  },
};
</script>
<style>
.v-label--active {
  color: #1c67fd !important;
  font-weight: bold;
}
</style>
